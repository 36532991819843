<template>
    <prime-card class="mx-6 mb-6 max-w-screen-xl lg:mx-12 lg:my-6 xl:mx-auto">
        <template #content>
            <span class="text-xl text-gray-700">Thanks!</span>
            <div class="my-2 text-4xl font-bold text-gray-900">
                Successful Submission
            </div>
            <p class="mb-4 mt-0 p-0 text-xl text-gray-700">
                We'll get back to you soon!
            </p>
            <div
                :style="{
                    height: '3px',
                    background:
                        'linear-gradient(90deg, rgb(var(--color-primary-600)) 0%, rgba(33, 150, 243, 0) 50%)'
                }"
            ></div>

            <div
                class="flex flex-col py-5 sm:flex-row sm:items-center sm:justify-between"
            >
                <div class="mb-3 sm:mb-0">
                    <span class="mr-2 text-xl font-medium text-gray-900"
                        >Ticket Reference:</span
                    >
                    <span class="text-xl font-medium text-primary-500"
                        >#{{ ticketData.reference }}</span
                    >
                </div>
            </div>

            <div class="mt-8 text-2xl">
                <label class="font-semibold">Subject</label>
                <p>
                    {{ ticketData.subject }}
                </p>
            </div>

            <div class="mt-8 text-2xl">
                <label class="font-semibold">Message</label>
                <div v-html="ticketData.comments.edges[0].node.bodyMarkup" />
            </div>

            <div
                v-if="showNavigationButtons"
                class="mt-16 flex justify-between"
            >
                <nuxt-link :to="profileLink()">
                    <prime-button
                        label="Back to Account"
                        outlined
                        severity="secondary"
                        icon="pi pi-fw pi-arrow-left"
                    />
                </nuxt-link>

                <nuxt-link :to="facilityHome">
                    <prime-button
                        label="Return to Facility"
                        outlined
                        severity="secondary"
                        icon="pi pi-fw pi-home"
                    />
                </nuxt-link>
            </div>
        </template>
    </prime-card>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const TicketConfirmationTicketFragment = graphql(/* GraphQL */ `
    fragment TicketConfirmationTicketFragment on Ticket {
        reference
        subject
        comments(first: 1) {
            edges {
                node {
                    bodyMarkup
                }
            }
        }
    }
`)
</script>

<script setup lang="ts">
import { useHead } from 'unhead'
import PrimeButton from 'primevue/button'
import nuxtLink from '#app/components/nuxt-link'
import { computed } from 'vue'
import PrimeCard from 'primevue/card'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData } from '~/resources/graphql'
import { useRouteLink } from '~/composables/routing'
import { useActiveFacility } from '~/composables/use-active-facility'

const props = defineProps<{
    ticket: FragmentType<typeof TicketConfirmationTicketFragment>
    showNavigationButtons: boolean
}>()

const ticketData = getFragmentData(
    TicketConfirmationTicketFragment,
    props.ticket
)

useHead({
    title: 'Contact Us'
})

const { facilityHomeLink, profileLink } = useRouteLink()
const activeFacility = useActiveFacility()
const facilityHome = computed(() => facilityHomeLink(activeFacility.value.slug))
</script>
